import React, { useState } from 'react';
import './ContactUs.css';

function SuccessScreen({ onOk }) {
    return (
        <div className="overlay">
            <div className="success-screen">
                <h2>🎉 Message Sent Successfully!</h2>
                <button onClick={onOk}>OK</button>
            </div>
        </div>
    );
}

function ContactUs() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [submitError, setSubmitError] = useState('');

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const isFormValid = () => {
        if (!formData.name || !formData.email || !formData.subject || !formData.message) {
            setSubmitError('⚠️ All fields are required. Please fill in all fields.');
            return false;
        }
        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!isFormValid()) {
            return;
        }

        const googleFormUrl = 'https://docs.google.com/forms/d/e/1FAIpQLSe8AGbLU2DwXLB2UsYMcjr2h0l4HMiQH9mK_YEHoNWmjK5TYA/formResponse';

        const submitData = new FormData();
        submitData.append('entry.2042123984', formData.name);
        submitData.append('entry.392015165', formData.email);
        submitData.append('entry.2062776782', formData.subject);
        submitData.append('entry.1339814012', formData.message);

        try {
            await fetch(googleFormUrl, {
                method: 'POST',
                body: submitData,
                mode: 'no-cors'
            });
            setIsSubmitted(true);
            setSubmitError('');
            setFormData({ name: '', email: '', subject: '', message: '' });
        } catch (error) {
            console.error("Form submission error:", error);
            setIsSubmitted(false);
            setSubmitError('⚠️ An error occurred while submitting the form.');
        }
    };

    const handleOkClick = () => {
        setIsSubmitted(false);
    };

    if (isSubmitted) {
        return <SuccessScreen onOk={handleOkClick} />;
    }

    return (
        <div className="contact-page">
            <div className="contact-form">
                <h2>Contact Us</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="name">Name:</label>
                        <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email:</label>
                        <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="subject">Subject:</label>
                        <input type="text" id="subject" name="subject" value={formData.subject} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="message">Message:</label>
                        <textarea id="message" name="message" value={formData.message} onChange={handleChange} required />
                    </div>
                    <button type="submit">Send Message</button>
                </form>
                {submitError && <div className="error-message">{submitError}</div>}
            </div>
        </div>
    );
}

export default ContactUs;
