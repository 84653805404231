import React, { useState } from "react";
import "./Blog.css";
import BlogPosts from "./BlogPost";

const Blog = () => {
    const categories = ["All", "Deep Learning", "Machine Learning", "AI Research"];
    const [selectedCategory, setSelectedCategory] = useState("All");

    return (
        <div className="blog-container">
            <section className="blog-hero">
                <h1>Explore AI & Deep Learning Blogs</h1>
                <p>Stay updated with the latest insights in artificial intelligence and cloud computing.</p>
            </section>

            <div className="blog-categories">
                {categories.map((category) => (
                    <button 
                        key={category} 
                        onClick={() => setSelectedCategory(category)}
                        className={selectedCategory === category ? "active" : ""}
                    >
                        {category}
                    </button>
                ))}
            </div>

            <section className="blog-grid">
                <BlogPosts category={selectedCategory} />
            </section>
        </div>
    );
};

export default Blog;
