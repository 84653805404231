import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from './components/Home/Home';
import Blog from './components/Blog/Blog';
import BlogDetail from './components/Blog/BlogDetail';
import About from './components/About/About';
import Research from './components/Research/Research';
import ContactUs from './components/ContactUs/ContactUs';
import './App.css';

const App = () => {
    return (
        <Router>
            <div>
                <nav className="navbar">
                    <ul>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/blog">Blog</Link></li>
                        <li><Link to="/about">About</Link></li>
                        <li><Link to="/research">Research</Link></li>
                        <li><Link to="/contactus">Contact Us</Link></li>
                    </ul>
                </nav>

                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/blog" element={<Blog />} />
                    <Route path="/blog/:id" element={<BlogDetail />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/research" element={<Research />} />
                    <Route path="/contactus" element={<ContactUs />} />
                </Routes>
            </div>
        </Router>
    );
};

export default App;
