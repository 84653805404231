import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Home.css';

const featuresData = [
    { id: 1, icon: "🚀", title: "AI-Powered Solutions", description: "Leverage AI to optimize business processes and drive efficiency." },
    { id: 2, icon: "☁️", title: "Scalable Cloud Computing", description: "Embrace cloud infrastructure with real-time data processing." },
    { id: 3, icon: "🔬", title: "Advanced Research", description: "Explore cutting-edge AI and ML research shaping the future." }
];

const Home = () => {
    const [fadeIn, setFadeIn] = useState(false);

    useEffect(() => {
        setTimeout(() => setFadeIn(true), 300); // Smooth fade-in effect
    }, []);

    return (
        <div className={`home ${fadeIn ? "fade-in" : ""}`}>
            {/* Hero Section */}
            <section className="hero">
                <h1>Revolutionizing AI & Cloud Technology</h1>
                <p>Innovate with the latest advancements in Artificial Intelligence and Cloud Computing.</p>
                <button className="cta-button" onClick={() => window.location.href='/contactus'}>Get in Touch</button>
            </section>

            {/* Features Section */}
            <section className="features">
                {featuresData.map((feature) => (
                    <div className="feature-box" key={feature.id}>
                        <h3>{feature.icon} {feature.title}</h3>
                        <p>{feature.description}</p>
                    </div>
                ))}
            </section>

            {/* Innovation Section */}
            <section className="innovation">
                <h2>Innovation at its Core</h2>
                <p>The convergence of AI and Cloud Technology is revolutionizing industries worldwide.</p>
                <Link to="/about" className="learn-more-btn">Learn More</Link>
            </section>

            {/* Footer */}
            <footer className="footer">
                <p>&copy; 2024 Galilee Research - AI & Cloud Innovation</p>
            </footer>
        </div>
    );
};

export default Home;
