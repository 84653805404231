import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import "katex/dist/katex.min.css";
import "./Blog.css";

const BlogDetail = () => {
    const { id } = useParams();
    const [content, setContent] = useState("");

    useEffect(() => {
        import(`../../content/blog${id}.md`)
            .then((res) => fetch(res.default))
            .then((response) => response.text())
            .then((text) => setContent(text))
            .catch((err) => console.error("Error loading markdown file", err));
    }, [id]);

    return (
        <div className="blog-container">
            <aside className="blog-sidebar">
                <h3>Related Blogs</h3>
                <ul>
                    <li><Link to="/blog/1">Machine Learning Explained</Link></li>
                    <li><Link to="/blog/2">Demystifying Deep Learning</Link></li>
                    <li><Link to="/blog/3">How Deep Learning Works</Link></li>
                    <li><Link to="/blog/4">How Neural Networks Work</Link></li>
                    <li><Link to="/blog/5">Why Are Activation Functions Needed?</Link></li>
                    <li><Link to="/blog/6">Implementing Neural Network Using Python</Link></li>
                    <li><Link to="/blog/7">Optimizing Neural Networks</Link></li>
                </ul>
            </aside>

            <div className="blog-content">
                <ReactMarkdown
                    remarkPlugins={[remarkGfm, remarkMath]}
                    rehypePlugins={[rehypeKatex]}
                    components={{
                        code({ node, inline, className, children, ...props }) {
                            const match = /language-(\w+)/.exec(className || "");
                            return !inline && match ? (
                                <SyntaxHighlighter
                                    style={atomDark}
                                    language={match[1]}
                                    PreTag="div"
                                    wrapLongLines={true}
                                    {...props}
                                >
                                    {String(children).replace(/\n$/, "")}
                                </SyntaxHighlighter>
                            ) : (
                                <code className={className} {...props} style={{ whiteSpace: "pre-wrap" }}>
                                    {children}
                                </code>
                            );
                        }
                    }}
                >
                    {content}
                </ReactMarkdown>
            </div>
        </div>
    );
};

export default BlogDetail;
