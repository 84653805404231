import React from "react";
import "./Research.css";

const Research = () => {
    const researchTopics = [
        {
            title: "The Rise of Large Language Models (LLMs)",
            description:
                "LLMs like GPT-3 and Megatron-Turing NLG have revolutionized NLP, enabling better chatbots, writing assistants, and AI-driven content generation.",
        },
        {
            title: "Advances in Computer Vision",
            description:
                "Deep learning has improved object detection, image segmentation, and facial recognition, making AI-powered vision more precise.",
        },
        {
            title: "Reinforcement Learning and AI Decision Making",
            description:
                "DeepMind’s AlphaFold and OpenAI’s Dota 2 bot showcase how reinforcement learning is solving complex decision-making problems.",
        },
        {
            title: "Federated Learning for Privacy-Preserving AI",
            description:
                "Federated learning enables AI training across multiple devices without sharing private data, enhancing security.",
        },
        {
            title: "Towards Explainable AI",
            description:
                "As AI grows more complex, making models interpretable and transparent remains a key challenge in responsible AI development.",
        },
    ];

    return (
        <div className="research-container">
            {/* Hero Section */}
            <section className="research-hero">
                <h1>Breakthroughs in AI, Deep Learning & Machine Learning</h1>
                <p>Explore the latest innovations shaping the future of artificial intelligence.</p>
            </section>

            {/* Research Grid */}
            <section className="research-grid">
                {researchTopics.map((topic, index) => (
                    <div key={index} className="research-card">
                        <h3>{topic.title}</h3>
                        <p>{topic.description}</p>
                    </div>
                ))}
            </section>

            {/* Footer */}
            <footer className="research-footer">
                <p>&copy; 2024 Galilee Research - AI & Cloud Innovation</p>
            </footer>
        </div>
    );
};

export default Research;
