import React from "react";
import "./About.css";

const About = () => {
    return (
        <div className="about-container">
            {/* Hero Section */}
            <section className="about-hero">
                <h1>About Us</h1>
                <p>Shaping the Future of AI & Cloud Innovation</p>
            </section>

            {/* Our Story */}
            <section className="about-section">
                <h2>Our Story</h2>
                <p>
                    In a world where AI, deep learning, and cloud technologies evolve at lightning speed,
                    understanding them can feel overwhelming. Our mission is simple: to demystify complex
                    tech concepts and make them accessible to everyone.
                </p>
            </section>

            {/* Our Audience */}
            <section className="about-section">
                <h2>Our Audience: The True North of Our Endeavor</h2>
                <p>
                    Our audience is our true north. We're more than a platform; we're a community where tech
                    veterans and novices alike can learn, engage, and contribute to the ever-evolving narrative
                    of AI and Cloud Technology.
                </p>
            </section>

            {/* Our Mission */}
            <section className="about-section">
                <h2>Our Mission</h2>
                <p>
                    In a world where technology's pace often outstrips comprehension, our mission stands clear:
                    to empower our readers by transforming the complex into the comprehensible. We're committed
                    to making AI and Cloud Technology accessible, engaging, and enlightening for all, irrespective
                    of their technical background.
                </p>
            </section>

            {/* Footer */}
            <footer className="about-footer">
                <p>&copy; 2024 Galilee Research - AI & Cloud Innovation</p>
            </footer>
        </div>
    );
};

export default About;
