import React from "react";
import { Link } from "react-router-dom";
import "./Blog.css";

const blogData = [
    { id: "1", title: "Machine Learning Explained", category: "Machine Learning", imageUrl: "/images/machine_learning.png" },
    { id: "2", title: "Demystifying Deep Learning", category: "Deep Learning", imageUrl: "/images/Deeplearning102.png" },
    { id: "3", title: "How Deep Learning Works", category: "Deep Learning", imageUrl: "/images/Deeplearning103.png" },
    { id: "4", title: "How Neural Networks Work", category: "Deep Learning", imageUrl: "/images/Deeplearning105.png" },
    { id: "5", title: "Why Are Activation Functions Needed?", category: "Deep Learning", imageUrl: "/images/Deeplearning107.png" },
    { id: "6", title: "Implementing Neural Network Using Python", category: "Deep Learning", imageUrl: "/images/Deeplearning99.png" },
    { id: "7", title: "Optimizing Neural Networks", category: "Deep Learning", imageUrl: "/images/Deep_Learning109.png" }
];

const BlogPosts = ({ category }) => {
    const filteredPosts = category === "All" ? blogData : blogData.filter(post => post.category === category);

    return (
        <div className="blog-grid">
            {filteredPosts.map((post) => (
                <div key={post.id} className="blog-card">
                    <Link to={`/blog/${post.id}`}>
                        <img src={process.env.PUBLIC_URL + post.imageUrl} alt={post.title} />
                        <h3>{post.title}</h3>
                    </Link>
                </div>
            ))}
        </div>
    );
};

export default BlogPosts;
